<template>
<div>
  <Header>
    <template #right-header-component>
     <ProfileDropDown/>

    </template>
  </Header>
  <div style="margin-top: 55px"  >
    <div class="tw-pt-16 tw-px-6">
      <div class=" tw-ml-4 sm:tw-ml-12 mb-2 ">
        <button @click="goBack()" class="tw-flex ">
          <ChevronLeftIcon />
          <p class="tw-font-bold tw-text-base " >Back</p>
        </button>
      </div>
      <v-row>
        <v-col cols="12" sm="12" md="3">

          <div  class="sm:tw-pl-12">
            <div>
              <h3 class="product_design text-capitalize" style="word-break: break-word">{{ poolNameAndDate.name }}</h3>
              <h4 class="subtitle py-2 text-capitalize">{{ poolNameAndDate.description }}e</h4>
              <h4 class="created">Created on {{ processedDate(poolNameAndDate.date_created) }}</h4>
           </div>
            <div>
              <h4 class="actions pt-6 pb-4">Actions</h4>
            </div>
            <div class="btn-card">
<!--              <v-card id="setScreeningTest" flat class="py-4 px-5">-->
<!--                <div class="d-flex justify-space-between align-center">-->
<!--                  <h4 class="card_text">Set Screening Test</h4>-->
<!--                  <ArrowRightIcon class="custom-class"/>-->
<!--                </div>-->
<!--              </v-card>-->
              <v-card id="uploadCsv" @click="goToUploadCSVPage()" flat class="py-4 px-5 csv">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="card_text">Upload CSV</h4>
                  <ArrowRightIcon class="custom-class"/>
                </div>
              </v-card>

            </div>
          </div>


        </v-col>
        <v-col  cols="12" sm="12" md="8">
          <div class="dataCard pa-5">
            <div class="mb-3">
              <v-row>
                <v-col sm="12" cols="12" md="3" class=" tw-order-1 md:tw-order-none ">
                  <div >
                    <v-text-field class="" v-model="search"  hide-details label="Search" solo/>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div :style="$vuetify.breakpoint.smAndDown? {minHeight:'35vh'}: {minHeight:'60vh'}" class="card-table">
            <v-data-table
                :loading="loading"
                :mobile-breakpoint="0"
                :headers="headers"
                :search="search"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                :items="all_candidates_in_a_talent_pool"
                item-key="id"
                checkbox-color="#8F47F4"
                :show-select="all_candidates_in_a_talent_pool.length > 0 ? true : false"
                :hide-default-footer="all_candidates_in_a_talent_pool.length > 0 ? false : true"
                @page-count="pageCount = $event"
                :height="$vuetify.breakpoint.smAndDown? '35vh': '55vh'"
                class="mt-4 mx-1 table"
            >
              <template v-slot:item.full_name="{ item }">
  <!--              <v-avatar class="avatar ml-n2" size="25">-->
  <!--                <h4 class="text-capitalize">{{ getInitials(item.full_name) }}</h4></v-avatar>-->
                <div class="d-flex align-center my-1">
                  <v-avatar class="avatar ml-n2" size="30">
                    <h4 class="text-capitalize">{{ getFullNameInitials(item.full_name) }}</h4>
                  </v-avatar>
                  <div class="ml-2">
                    <h4 class="fullName text-capitalize">{{item.full_name?item.full_name: "--"}}</h4>
                    <h4 class="email">{{item.candidate_email}}</h4>
                  </div>
                </div>

              </template>

              <template v-slot:item.created_by="{ item }">
                <div class="d-flex align-center ">
                  <v-avatar class="avatar ml-n2" size="30">
                    <h4 class="text-capitalize">{{ getAddedByInitials(item.created_by) }}</h4>
                  </v-avatar>
                  <h4 class="text-capitalize fullName ml-2">{{item.created_by}}</h4>
                </div>
              </template>

              <template v-slot:item.roles="{ item }">
                <div>
                  <h4 class="text-capitalize fullName">{{item.roles}}</h4>
                </div>
              </template>

              <template v-slot:[`item.kebab`]="{ item }">
                <v-menu bottom offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon color="black">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="mx-1 mt-1">
                      <MoveToTalentPoolModal :item="item">
                        <v-list-item-title id="move_to_talent_pool">Move to Talent Pool</v-list-item-title>
                      </MoveToTalentPoolModal>

                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

            </v-data-table>
          </div>
          </div>

        </v-col>
      </v-row>
    </div>
  </div>

</div>
</template>

<script>
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import {ArrowRightIcon, ChevronLeftIcon} from "vue-feather-icons"
import MoveToTalentPoolModal from "@/components/talentPool/MoveToTalentPoolModal";
import getSiteName from "@/services/utils";
import {mapGetters} from "vuex";
export default {
name: "AllCandidateInATalentPool",
  components: {MoveToTalentPoolModal, ProfileDropDown, Header, ArrowRightIcon, ChevronLeftIcon},
  data(){
  return{
    loading: false,
    search: "",
    tableData: [],
    noData: 'You don’t have any job listing ',
    filteredResult : [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    headers: [
      {
        text: "CANDIDATES",
        align: "start",
        sortable: false,
        value: "full_name"
      },
      {text: "ROLE", value: "roles"},
      {text: "ADDED BY", value: "added_by"},
      {text: "", value: "kebab"}

    ],
  }
  },
  created() {
    this.loading = true
    let site_name = getSiteName()
    let pool_id = this.$route.params.talentPoolId
    console.log(pool_id, site_name)
    this.$store.dispatch("talentPool/getAllCandidatesInATalentPool",{site_name,pool_id})
    .then(res => {console.log(res)
          // this.tableData = res.data.reverse()
          this.loading = false
        })
        .catch(err => {console.log(err)
          this.loading = false})
  },
  methods:{
    goBack(){
      this.$router.push({name: 'AllTalentPoolPage'})
    },
    goToUploadCSVPage(){
      this.$router.push({name: 'UploadCandidatesPage', params:{talentPoolId: this.$route.params.talentPoolId}})
    },
    getAddedByInitials(name) {
      let  firstNameInitial = name.split(" ")[0].substring(0,1);
      return firstNameInitial
    },
    getFullNameInitials(name) {
      // console.log(name)

      let  firstNameInitial = name.split(" ")[0].substring(0,1);
      let  lastNameInitial = name.split(" ")[1];

      if(name === "" ){
        return "--"
      }else if (lastNameInitial === "" || lastNameInitial === undefined ){
        return firstNameInitial
      }
      else return firstNameInitial + lastNameInitial.substring(0,1)
      // console.log(lastNameInitial, "index 2")
      // return firstNameInitial
    },
    processedDate(gottenDate){
      let dateAndTime = gottenDate.split("T");
      let date = dateAndTime[0];
      let newDate = new Date(date).toDateString();
      let month = newDate.split(" ")[1]
      let day = newDate.split(" ")[2]
      let year = newDate.split(" ")[3]
      return day + " "+ month + ", " + year
    },
  },
  computed:{
    ...mapGetters("talentPool",["all_candidates_in_a_talent_pool"]),
    poolNameAndDate(){
      return JSON.parse(sessionStorage.getItem("poolNameAndDate"));
    }
  }
}
</script>

<style scoped lang="scss">
.dataCard{

  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
  border-radius: 8px;
}
h3, h4{
  font-family: DM Sans;
  font-style: normal;
}
.product_design{
  font-family: IBM Plex Serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.subtitle{
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.created{
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.actions{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
}
.custom-class{
  color: #9CABB5;
}
.btn-card{
  //height: 115px;
  //width: 265px;
  border-radius: 8px;
  //padding: 16px, 19px, 16px, 19px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}
.card_text{
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.avatar{
  background: #EEF8FF;
  border: 1px solid #FFFFFF;
}
.avatar h4{
  color: #008EEF;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}
.email{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #9CABB5;
}
.fullName{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

</style>